div label input {
    margin-right:100px;
 }
 body {
     font-family:sans-serif;
 }
 
 #ck-button {
     margin:4px;
     background-color:#EFEFEF;
     border-radius:4px;
     border:1px solid #D0D0D0;
     overflow:auto;
     float:left;
 }
 
 #ck-button label {
     float:left;
     width:5.0em;
 }
 
 #ck-button label span {
     text-align:center;
     padding:3px 0px;
     display:block;
 }
 
 #ck-button label input {
     position:absolute;
     top:-20px;
 }
 
 #ck-button input:checked + span {
     background-color:#64CCC5;
     color:#fff;
 }